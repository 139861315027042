// extracted by mini-css-extract-plugin
export var caseContactFormSection = "bm_kC";
export var caseStudyBackground__lineColor = "bm_kz";
export var caseStudyHead = "bm_kt";
export var caseStudyHead__imageWrapper = "bm_kv";
export var caseStudyProjectsSection = "bm_kD";
export var caseStudyQuote__bgLight = "bm_kK";
export var caseStudyQuote__bgRing = "bm_kx";
export var caseStudyVideo = "bm_kM";
export var caseStudyVideo__ring = "bm_kN";
export var caseStudy__bgDark = "bm_ks";
export var caseStudy__bgLight = "bm_kr";